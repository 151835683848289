let Patient = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    let PatientSession = 'PatientSession'

    /**
     * 机构后台患者列表
     * @param patientType
     * @param organitionId
     * @param userId
     * @param startTime
     * @param endTime
     * @param keyWord
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.PatientList = function (patientType, organitionId, userId, startTime, endTime, keyWord, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/GetPatientListMyself';
        if (patientType) {
            url += `/${patientType}`;
        }
        if (organitionId) {
            url += `/${organitionId}`;
        }
        if (userId) {
            url += `/${userId}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        } else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        } else {
            url += '/%20';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 患者详情
     * @param patientId
     * @param success
     * @param error
     * @constructor
     */
    this.GetDetail = function (patientId, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/GetPatient/'+patientId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    
	this.GetPatientDiagnoseDetail = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Patient/GetPatientDiagnose/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
    
	this.MZPrintPrescription = function(patientDiagnoseId,userId, success, error) {
		var url = ServiceBaseAddress + `/api/Patient/PrintPrescription/${patientDiagnoseId}/${userId}`
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

    /**
     * 添加患者
     * @param item
     * @param success
     * @param error
     */
    this.patientAdd = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/AddPatient'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 编辑患者
     * @param item
     * @param success
     * @param error
     */
    this.patientEdit = function(item, success, error) {
        if (item && item.id) {
            var url = ServiceBaseAddress + '/api/Patient/EditPatient'
            TokenClient.Put(url, true, {'Content-Type': 'application/json'}, item,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data)
                        success(jsonObj)
                    }
                },
                error
            )
        }
    }

    /**
     * 删除患者
     * @param patineId
     * @param success
     * @param error
     */
    this.patientRemove = function(patineId, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/DeletePatient/' + patineId
        TokenClient.Delete(url, true, null, null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 所有复诊记录列表
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.DiagnoseList = function (pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/GetPatientDiagnoseList/'+pageIndex;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.DiagnoseDetial = function (patientDiagnoseId, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/GetPatientDiagnose/'+patientDiagnoseId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 添加复诊记录
     * @param item
     * @param success
     * @param error
     */
    this.DiagnoseAdd = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/AddDiagnose'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 编辑复诊记录
     * @param item
     * @param success
     * @param error
     */
    this.DiagnoseEdit = function(item, success, error) {
        if (item && item.id) {
            var url = ServiceBaseAddress + '/api/Patient/EditDiagnose'
            TokenClient.Post(url, true, {'Content-Type': 'application/json'}, item,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data)
                        success(jsonObj)
                    }
                },
                error
            )
        }
    }
    this.Patients = function (success, error) {
        var url = ServiceBaseAddress + '/api/Patient/GetPatientAll';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.UserList = function (userId,success, error) {
        var url = ServiceBaseAddress + '/api/User/Info/'+userId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /*获取部门在session数据*/
    this.GetSessionPatient = function () {
        var result = sessionStorage.getItem(PatientSession);
        if (result == null) {
            this.Patients(function (data) {
                    /*保存部门到缓存*/
                    sessionStorage.setItem(
                        PatientSession,
                        JSON.stringify(data.data)
                    );
                    result = data.data;
                },
                function (err) {
                    console.log(err);
                });
        } else {
            result = JSON.parse(result);
        }
        return result;
    }
    /**
     * 机构历史患者
     * @param success
     * @param error
     * @constructor
     */
    this.GetOrganizationPatientList = function (startTime, endTime, keyWord, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/GetOrganizationPatientList'
        if (startTime) {
            url += `/${startTime}`
        } else {
            url += '/%20'
        }
        if (endTime) {
            url += `/${endTime}`
        } else {
            url += '/%20'
        }
        if (keyWord) {
            url += `/${keyWord}`
        } else {
            url += '/%20'
        }
        if (pageIndex) {
            url += `/${pageIndex}`
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            }, error)
    }
    this.GetOrganizationPatientAll = function (success, error) {
        var url = ServiceBaseAddress + '/api/Patient/GetOrganizationPatientAll '
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            }, error)
    }

}
export { Patient }
