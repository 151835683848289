<template>
    <div class="height1">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="姓名/手机号"
                                    @keyup.enter.native="search" clearable ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                                <el-button type="primary" size="small" @click="toAdd()" plain>添加患者</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 90px)" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="name" label="姓名">
                    </el-table-column>
                    <el-table-column prop="sex" label="性别" align="center">
                        <template slot-scope="scope">
                            <el-tag type="primary" size="small" v-if="scope.row.sex == true">男</el-tag>
                            <el-tag type="warning" size="small" v-if="scope.row.sex == false">女</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="age" label="年龄" align="center">
                    </el-table-column>
                    <el-table-column prop="phone" label="手机号" align="center">
                    </el-table-column>
                    <el-table-column prop="idNo" label="身份证号" align="center">
                    </el-table-column>
                    <el-table-column prop="DiagnosisTimes" label="复诊记录" align="center">
                        <template slot-scope="scope">
                            <el-link v-if="scope.row.patientDiagnose.length" type="primary" size="small"
                                @click="toRecord(scope.row)">{{ scope.row.patientDiagnose.length }}</el-link>
                            <span v-else>0</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="diagnosis" label="疾病诊断" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.patientDiagnose.length">
                                {{ scope.row.patientDiagnose[0].diseaseDiagnose }} </span>
                            <span v-else> - </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="最近一次就诊"  align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.patientDiagnose.length"> {{ scope.row.patientDiagnose[0].addTime |
                                    timefilters
                            }} </span>
                            <span v-else> - </span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="250" align="center">
                        <template slot-scope="scope">
                            <span class="warning optionBtn" @click="toEdit(scope.row)" >编辑</span>
                            <span class="success optionBtn" @click="addDR(scope.row)" >添加复诊</span>
                            <span class="success optionBtn" @click="toList(scope.row)" >复诊记录</span>
                            <!--                      <span type="primary" size="mini" @click="addRX(scope.row)" plain>开具处方</span>-->
                            <span class="danger optionBtn" @click="remove(scope.row)" >删除</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { Patient } from "../../../components/HospitalDomain/Patient/Patient";

export default {
    name: "Patient",
    components: {
        bread
    },
    data() {
        var patient = new Patient(this.TokenClient, this.Services.Authorization)
        return {
            patientDomain: patient,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            tableData: [],
            searchForm: {
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
        }
    },
    mounted() {
        this.GetList();
    },
    methods: {
        SearchInputKeydown() {
            this.PageIndex = 1;
            this.GetList();
        },
        search() {
            console.log(this.searchForm.datepicker)
            this.PageIndex = 1;
            this.GetList();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.GetList();
        },
        GetList() {
            var _this = this;
            _this.tableData = [];
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = '';
                item.endTime = '';
            }
            _this.patientDomain.PatientList('-999', _this.organizationId, '0', item.startTime, item.endTime, item.keyWord, _this.PageIndex,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                },
                function (error) {
                    console.log(error);
                });
        },
        toRecord(item) {
            this.$router.push({
                path: '/Patient/DiagnosticRecord',
                query: {
                    id: item.id,
                }
            })
        },
        toAdd() {
            this.$router.push({
                path: '/Patient/AddPatient',
                query: { title: '新增患者' }
            })
        },
        toEdit(item) {
            this.$router.push({
                path: '/Patient/AddPatient',
                query: {
                    id: item.id,
                    title: '编辑患者'
                }
            })
        },
        addDR(item) {
            this.$router.push({
                path: '/Patient/AddDiagnostic',
                query: {
                    id: item.id,
                }
            })
        },
        toList(item) {
            this.$router.push({
                path: '/Patient/DiagnosticRecord',
                query: {
                    id: item.id,
                }
            })
        },
        addRX(item) {
            this.$router.push({
                path: '/Patient/Prescribing',
                query: {
                    id: item.id,
                }
            })
        },
        remove(item) {
            var _this = this;
            _this.$confirm('是否删除此患者信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                // type: 'warning'
            }).then(() => {
                _this.patientDomain.patientRemove(item.id,
                    function (data) {
                        _this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        _this.GetList()
                    },
                    function (err) {
                        console.log(err);
                    });
            }).catch(() => {

            });
        }
    }
}
</script>

<style scoped>
/*content*/


.table-top {
    /* margin-bottom: 15px; */
}

.ParameterForm .el-input {
    margin-right: 10px;
    width: 150px;
    vertical-align: top;
}
</style>
